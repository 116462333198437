var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"adjust-summary pa-3 mt-3"},[_c('div',{staticClass:"adjust-summary__container"},_vm._l((_vm.summaryData),function(batch,index){return _c('div',{key:index},[(batch.hasErrors)?_c('div',{staticClass:"red--text text-md-center font-weight-bold"},[(batch.errors.length)?_c('div',{staticClass:"mb-2"},[_vm._v("\n          "+_vm._s(_vm.$t("batches.adjustment_errors_message"))+"\n          "),_vm._l((batch.errors),function(message,index){return _c('div',{key:index},[_vm._v(_vm._s(message))])})],2):_c('div',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.$t("batches.adjustment_unknow_error_message")))])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"adjust-summary__container-data"},[_c('div',{staticClass:"adjust-summary__container-data--left"},_vm._l((batch),function(value,name,batchIndex){return _c('div',{key:("batch-" + batchIndex)},[(name !== 'rooms' && name !== 'hasErrors' && name !== 'errors')?[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.batchLabel[name]))+":")]),_vm._v(" "),_c('span',{class:{
                  'font-weight-bold': name === 'name'
                }},[_vm._v(_vm._s(_vm.$t(value)))])]:_vm._e()],2)}),0),_vm._v(" "),_c('div',{staticClass:"adjust-summary__container-data--right"},_vm._l((batch.rooms),function(room,roomsIndex){return _c('div',{key:("room-" + roomsIndex),staticClass:"adjust-summary__container-data--right__room"},_vm._l((room),function(value,name,roomIndex){return _c('div',{key:("a-" + roomIndex),staticClass:"adjust-summary__container-data--right__room--qty"},[(typeof value !== 'object')?[_c('p',{class:{
                    'font-weight-bold': !roomsIndex || name === 'room_name',
                    'text-md-center': roomIndex || name !== 'room_name',
                    'font-a': name !== 'room_name' && roomsIndex,
                    'blue--text':
                      name === 'current_quantity' &&
                      batch.rooms.length - 1 === roomsIndex,
                    'green--text':
                      name === 'new_quantity' &&
                      batch.rooms.length - 1 === roomsIndex,
                    'red--text':
                      name === 'difference' &&
                      batch.rooms.length - 1 === roomsIndex
                  }},[_vm._v("\n                  "+_vm._s(_vm.$t(("" + value)))+"\n                ")])]:_vm._e(),_vm._v(" "),(typeof value === 'object')?[_c('p',{staticClass:"red--text text-md-center font-a"},[_vm._v("\n                  "+_vm._s(value.value)),_c('span',{staticClass:"grey--text"},[_vm._v(_vm._s(value.percentage))])])]:_vm._e()],2)}),0)}),0)]),_vm._v(" "),_c('v-divider',{staticClass:"divider"})],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }