import { InventoryBatch } from "@/interfaces/batch";
import { Room } from "@/interfaces/room";
import { batchService } from "@/services/batch.service";
import { sumArray } from "@/utils/math.utils";
import { NumericPickerComponent } from "helix-vue-components";
import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import round from "lodash/round";
import { Validator } from "vee-validate";
import { Component, Inject, Prop, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import SidebarListComponent from "../../common/BatchSideBarList/SidebarList.component";
import AdjustSummaryComponent from "../adjust-summary/AdjustSummary.component";
import { setPercentage } from "../adjust.util";
import {
  AdjustForm,
  AdjustItem,
  AdjustmentType,
  AdjustOperation,
  SummaryData
} from "../models/batch-adjust.model";
import Template from "./AdjustForm.template.vue";

@Component({
  mixins: [Template],
  components: {
    "list-component": SidebarListComponent,
    "adjust-summary": AdjustSummaryComponent,
    "numeric-picker": NumericPickerComponent
  }
})
export default class AdjustFormComponent extends Vue {
  @Prop({ required: true })
  public bioTrackTraceEnabled!: boolean;
  @Prop({ required: true })
  public operationData!: AdjustOperation;
  @Prop({ default: () => [] })
  public rooms!: Room[];
  @Prop({ required: true })
  public value!: AdjustItem[];
  @Prop({ default: () => [] }) public summaryData!: SummaryData[];
  @Prop({ default: false }) public isFinished!: boolean;
  @Prop({ required: true })
  public batches!: InventoryBatch[];
  @Prop({ required: true })
  public batchIndex!: number;
  @Prop() public formDataSummary!: Array<{
    batch_uid: string;
    form?: AdjustForm[] | InventoryBatch[];
  }>;
  @Inject("$validator") public $validator!: Validator;

  public adjustmentTypes: AdjustmentType[] = [];
  public isValid = false;
  public adjustType: number | null = null;
  public adjustmentReason: string | null = null;

  public batchDetails = {
    productName: {
      path: "product.name",
      label: "batch_combine.product_placeholder"
    },
    barcode: {
      path: this.getTraceId(),
      label: "barcode"
    },
    secondaryId: {
      path: "tracking_id",
      label: "batch_combine.secondary_id"
    },
    batchType: {
      path: "product.batch_type.name",
      label: "inventory.batch_type"
    },
    strain: {
      path: "product.strain.name",
      label: "batch_combine.strain"
    },
    usableWeight: {
      path: "product.usable_weight_value",
      label: "usable_weight"
    }
  };

  @Watch("batchIndex")
  public onBatchIndexChange() {
    this.resetForm();
    this.setQuantities();
  }

  public getTraceId() {
    if (
      this.bioTrackTraceEnabled &&
      this.batches[0].biotrack_traceability_id !== ""
    ) {
      this.batches[0].batch_uid = this.batches[0].biotrack_traceability_id!;
      return "batch_uid";
    }
    return "batch_uid";
  }
  public getCurrentVal(path: string) {
    return get(this.batches[this.batchIndex], path, "--");
  }

  public getCurrentQuantity(type?: string) {
    if (type === "Reserve") {
      return (
        sumArray(this.model[this.batchIndex].form, "reserved_quantity") +
          sumArray(
            this.formDataSummary[this.batchIndex].form!,
            "reserved_quantity"
          ) || "--"
      );
    }
    if (type === "Total") {
      return (
        sumArray(this.model[this.batchIndex].form, "reserved_quantity") +
          sumArray(this.model[this.batchIndex].form, "old_quantity") +
          sumArray(
            this.formDataSummary[this.batchIndex].form!,
            "reserved_quantity"
          ) +
          sumArray(
            this.formDataSummary[this.batchIndex].form!,
            "old_quantity"
          ) || "--"
      );
    }
    return (
      sumArray(this.model[this.batchIndex].form, "old_quantity") +
        sumArray(this.formDataSummary[this.batchIndex].form!, "old_quantity") ||
      "--"
    );
  }

  public getRoomName(roomId: number): string {
    const room = this.rooms.find(r => r.id === roomId);
    return (room && room.name) || "--";
  }

  public get disableAdjust(): boolean {
    return !this.isValid;
  }

  public get model() {
    return cloneDeep(this.value);
  }

  /** Updates the quantity showed in top of the component */
  public onQuantityChange() {
    const oldQuantityTotal = sumArray(
      this.model[this.batchIndex].form,
      "old_quantity"
    );
    this.model[this.batchIndex].newQuantityTotal = sumArray(
      this.model[this.batchIndex].form,
      "new_quantity"
    );

    this.model[this.batchIndex].difference = Math.abs(
      round(
        this.model[this.batchIndex].newQuantityTotal! - +oldQuantityTotal,
        2
      )
    );
    this.model[this.batchIndex].differencePercentage =
      this.model[this.batchIndex].newQuantityTotal === 0
        ? 100
        : setPercentage(
            +this.model[this.batchIndex].newQuantityTotal!,
            oldQuantityTotal || 1
          );

    this.emitChanges();
  }

  /** Returns types of adjustment available from API */
  public async loadAdjustmentTypes() {
    this.adjustmentTypes = await batchService.getAdjustmentTypes();
  }

  public async onAdjust() {
    if (await this.$validator.validateAll()) {
      for (const form of this.model[this.batchIndex].form) {
        form.adjustment_reason = this.adjustmentReason;
        form.batch_adjustment_type_id = this.adjustType;
      }
      this.emitChanges();
      this.$emit("forwardAjust");
    }
  }

  public emitChanges() {
    this.$emit("input", this.model);
  }

  protected resetForm() {
    this.adjustType = null;
    this.adjustmentReason = null;
    this.$validator.reset();
  }

  protected setQuantities() {
    this.model[this.batchIndex].form = this.model[this.batchIndex].form.map(
      i => ({ ...i, new_quantity: +cloneDeep(i.old_quantity!) })
    );
  }

  protected async created() {
    this.setQuantities();
    this.loadAdjustmentTypes();
  }
}
