import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { SummaryData } from "../models/batch-adjust.model";
import Template from "./AdjustSummary.template.vue";

@Component({
  mixins: [Template]
})
export default class AdjustSummaryComponent extends Vue {
  @Getter("hasBioTrackTraceIntegrations", { namespace: "AuthModule" })
  public hasBioTrackTraceIntegrations!: boolean;
  @Getter("bioTrackTraceEnabled", { namespace: "AuthModule" })
  public bioTrackTraceEnabled!: boolean;
  @Prop({ default: () => [] }) public summaryData!: SummaryData[];
  public batchLabel = {
    product_name: "batches.product",
    secondary_id: "batches.secondary_id",
    batch_type: "batches.batch_type",
    strain: "strain.strain",
    usable_weight_value: "batches.usable_weight",
    batch_uid: "batch_uid"
  };
}
