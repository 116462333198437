import { InventoryBatch } from "@/interfaces/batch";
import toInteger from "lodash/toInteger";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import Template from "./SidebarList.template.vue";

@Component({
  mixins: [Template]
})
export default class SidebarListComponent extends Vue {
  @Prop({ default: "" }) public title!: string;
  @Prop({ default: () => [] }) public batches!: InventoryBatch[];
  @Prop({ default: false }) public isFinished!: boolean;
  @Prop({ default: 0, required: true }) public current!: number;
  @Getter("hasBioTrackTraceIntegrations", { namespace: "AuthModule" })
  public hasBioTrackTraceIntegrations!: boolean;
  @Getter("bioTrackTraceEnabled", { namespace: "AuthModule" })
  public bioTrackTraceEnabled!: boolean;
}
