import LoadingWindowComponent from "@/components/metrc/loadingWindow/loadingWindow.component";
import TraceabilityConfirm from "@/components/sharedComponents/traceabilityConfirm/TraceabilityConfirm.component";
import { EventBus } from "@/event-bus";
import {
  BatchAction,
  CustomBatch,
  InventoryBatch,
  PBSummary
} from "@/interfaces/batch";
import { Room } from "@/interfaces/room";
import { i18n } from "@/plugins/i18n";
import { metrcEnabled } from "@/router.utils";
import { batchOperationsService } from "@/services/batchOperations.service";
import { messagesService } from "@/services/messages.service";
import { CallbackPromise, PageNavAction } from "@/types/types";
import { mapBatchesToAction } from "@/utils/batch-actions.utils";
import { sumArray } from "@/utils/math.utils";
import { AxiosError } from "axios";
import { ActionsSubheaderComponent } from "helix-vue-components";
import cloneDeep from "lodash/cloneDeep";
import every from "lodash/every";
import groupBy from "lodash/groupBy";
import reduce from "lodash/reduce";
import round from "lodash/round";
import some from "lodash/some";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import QuantitiesReservationComponent from "../common/QuantitiesReservation/QuantitiesReservation.component";
import AdjustFormComponent from "./adjust-form/AdjustForm.component";
import Template from "./Adjust.template.vue";
import { setPercentage } from "./adjust.util";
import {
  AdjustForm,
  AdjustForwardResponse,
  AdjustItem,
  AdjustOperation,
  AdjustRawItem,
  SummaryData,
  SummaryRoom
} from "./models/batch-adjust.model";

@Component({
  mixins: [Template],
  components: {
    "adjust-form": AdjustFormComponent,
    "quantities-reservation": QuantitiesReservationComponent
  }
})
export default class AdjustComponent extends Vue {
  @Getter("hasBioTrackTraceIntegrations", { namespace: "AuthModule" })
  public hasBioTrackTraceIntegrations!: boolean;
  @Getter("bioTrackTraceEnabled", { namespace: "AuthModule" })
  public bioTrackTraceEnabled!: boolean;
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;
  @Action("loadAllRooms", { namespace: "RoomModule" })
  public loadRooms!: CallbackPromise<void>;

  @Getter("allRooms", { namespace: "RoomModule" })
  public allRooms!: Room[];
  @Getter("getAdjustBatch", { namespace: "BatchModule" })
  public adjustBatch!: BatchAction<CustomBatch>;

  /** Data that will be manipulated by the user */
  public formData: AdjustItem[] = [];
  public formDataSummary: Array<{
    batch_uid: string;
    form?: AdjustForm[] | InventoryBatch[];
  }> = [];
  /** Data retrieved from Inventory  */
  public batches: InventoryBatch[] = [];
  /** Data to be showed in Summary */
  public summaryData: Array<Partial<SummaryData>> = [];
  public formattedBatches: BatchAction<CustomBatch> | null = null;
  public isReservationValid = true;
  public step = 0;
  public loading = false;
  public disableFinish = true;
  public isFinished = false;
  public operationData: AdjustOperation | null = null;
  public hasMetrc = metrcEnabled();
  public batchIndex: number = 0;

  public async created() {
    this.setPageNav({
      title: "batches.batch_adjust",
      rightActions: {
        generalActions: () => [
          {
            action: this.back,
            buttonText: "batches.back_to_inventory",
            visibleCondition: () => this.isFinished,
            vuetifyProps: () => ({
              loading: this.loading
            })
          },
          {
            action: this.onAdjustFinish,
            buttonText: "batches.finish",
            visibleCondition: () => !this.isFinished && this.step === 1,
            vuetifyProps: () => ({
              loading: this.loading
            })
          },
          {
            visibleCondition: () => this.step === 0,
            action: this.reserveQuantities,
            buttonText: "batch_convert.reserve",
            vuetifyProps: () => ({
              disabled: !this.isReservationValid,
              loading: this.loading
            })
          }
        ]
      },
      leftActions: {
        component: ActionsSubheaderComponent,
        props: {
          generalActions: [
            {
              icon: "fal fa-chevron-left",
              visibleCondition: () => this.step === 0,
              action: this.back
            }
          ]
        }
      }
    });
    this.batches = JSON.parse(this.$route.params.batches);
    this.formattedBatches = mapBatchesToAction(this.batches, null, "ADJUST");
    if (!this.allRooms.length) {
      this.loadRooms();
    }
  }

  /** Returns to the inventory */
  public back() {
    this.$router.back();
  }

  public updateReservationData(e: {
    reservation: BatchAction<CustomBatch>;
    valid: boolean;
  }) {
    this.formattedBatches = e.reservation;
    this.isReservationValid = e.valid;
  }

  public async reserveQuantities() {
    this.formattedBatches = this.adjustBatch;
    this.loading = true;
    const reservation = await batchOperationsService.createOperation(
      this.formattedBatches!,
      "adjust"
    );
    if (reservation) {
      await this.fetchAdjustment(reservation.reference);
      this.buildForm();
      this.step = 1;
    }
    this.loading = false;
  }

  public async popUp(
    titleName: string,
    messageName: string,
    descriptionName: string,
    loadingName: string,
    isAcceptButton: boolean,
    isCancleButton: boolean,
    acceptButtonValue: string
  ) {
    const confirm = (await this.$modals
      .load(
        TraceabilityConfirm,
        { size: "normal", positionX: "center", positionY: "center" },
        {
          modalData: {
            titleAvatar: {
              name: "/img/icon_primary_menu_inventory@2x.9f2161a2.png",
              size: "100"
            },
            title: {
              name: this.$t(titleName),
              style: "fontSize:35px ; letter-spacing: 0px;"
            },
            message: {
              name: this.$t(messageName),
              style: "fontSize:28px ; fontWeight:600"
            },
            description: {
              name: this.$t(descriptionName),
              style: "fontSize:23px"
            },
            loading: this.$t(loadingName),
            acceptButton: isAcceptButton,
            cancelButton: isCancleButton,
            acceptButtonValue
          }
        }
      )
      .catch(() => false)) as boolean;
    if (!confirm) {
      return;
    }
  }

  public async adjustLoadingPopUp() {
    if (await this.$validator.validateAll()) {
      this.popUp(
        "biotrack_traceability.inventory_/_batch_adjust",
        "biotrack_traceability.adjustment_process",
        "biotrack_traceability.adjusting_the_quantity_in_biotrack_traceability",
        "audit.loading",
        false,
        false,
        ""
      );
    }
  }

  public async onForwardAdjust() {
    if (this.hasBioTrackTraceIntegrations && this.bioTrackTraceEnabled) {
      this.loading = true;
      const response: AdjustForwardResponse = await batchOperationsService.forwardAjust(
        this.operationData!.reference,
        this.formData[this.batchIndex],
        this.adjustLoadingPopUp
      );
      EventBus.$emit("removePopup", true);
      if (!response.pinCode) {
        this.loading = false;

        if (response.errors) {
          messagesService.renderErrorMessage(response.errors);
          this.hideLoadingWindow();
        }
        return false;
      }

      if (response.success) {
        this.showForwardSuccess();
      } else {
        this.popUp(
          "biotrack_traceability.inventory_/_batch_adjust",
          "biotrack_traceability.adjustment_failed",
          i18n.t(
            "biotrack_traceability.the_adjustment_failed_in_biotrack_traceability_with_the_following_response_from_the_api"
          ) +
            `${
              response.errors!.response!.data.data.length !== 0
                ? response.errors!.response!.data.data
                : response.errors!.response!.data.message.error
                ? response.errors!.response!.data.message.error
                : response.errors!.response!.data.message
            }`,
          "",
          true,
          false,
          "ok"
        );
        this.loading = false;
      }
      this.updateOperationData(response!);
      this.updateBatches();
      this.disableFinish = !this.batches.find(item => !!item.isDone);
      if (this.batchIndex + 1 < this.formData.length) {
        this.batchIndex++;
      }
      if (
        (!this.hasBioTrackTraceIntegrations || response.success) &&
        every(this.batches, "isDone")
      ) {
        this.onAdjustFinish();
      }
      this.loading = false;
    } else {
      this.loading = true;
      const response: AdjustForwardResponse = await batchOperationsService.forwardAjust(
        this.operationData!.reference,
        this.formData[this.batchIndex],
        this.showLoadingWindow
      );
      if (!response.pinCode) {
        this.loading = false;

        if (response.errors) {
          messagesService.renderErrorMessage(response.errors);
          this.hideLoadingWindow();
        }
        return false;
      }

      if (response.success) {
        this.showForwardSuccess();
      } else {
        this.showForwardErrors(response.errors!);
      }

      this.updateOperationData(response!);
      this.updateBatches();
      this.disableFinish = !this.batches.find(item => !!item.isDone);

      if (this.batchIndex + 1 < this.formData.length) {
        this.batchIndex++;
      }

      if (
        (!this.hasMetrc || response.success) &&
        every(this.batches, "isDone")
      ) {
        this.onAdjustFinish();
      }

      this.loading = false;
    }
  }

  /**Post to the API and cleans viewData to be displayed
   * in Summary View
   */
  public async onAdjustFinish() {
    this.loading = true;

    const response = await batchOperationsService.finishAdjust(
      this.operationData!.reference
    );

    if (response) {
      if (some(this.batches, "isDone")) {
        this.isFinished = true;
        this.createSummary();
      } else {
        this.back();
      }
    }

    this.loading = false;
  }

  protected showLoadingWindow(): void {
    if (this.hasMetrc && this.hasMarijuana()) {
      this.$modals
        .load(LoadingWindowComponent, {
          closable: false,
          size: "fit",
          positionY: "center",
          positionX: "center",
          backdrop: true
        })
        .catch(() => {
          if (every(this.batches, "isDone")) {
            this.onAdjustFinish();
          }
        });
    }
  }

  protected hideLoadingWindow(): void {
    if (this.hasMetrc) {
      EventBus.$emit("mtrcLoadingEvent", {
        show: false
      });
    }
  }

  protected showForwardSuccess() {
    this.hideLoadingWindow();
    messagesService.renderSuccessMessage("batch_move.success");
  }

  protected showForwardErrors(errors: AxiosError) {
    if (this.hasMetrc) {
      EventBus.$emit("mtrcLoadingEvent", {
        show: true,
        errorList: messagesService.parseMetrcError(errors)
      });
    } else {
      messagesService.renderErrorMessage(errors);
    }
  }

  protected updateOperationData(response: AdjustForwardResponse) {
    const batchUID = this.formData[this.batchIndex].form[0].batch_uid;
    const newOperation = cloneDeep(this.operationData);
    let errorsString: string[] = [];

    if (!response.success) {
      errorsString = messagesService
        .parseMetrcError(response.errors!)
        .map(error => {
          return error.message;
        });
    }

    for (const item of newOperation!.items) {
      if (item.batch_uid === batchUID) {
        const adjustForm = this.formData[this.batchIndex].form.find(
          form => form.inventory_location_id === item.inventory_location_id
        );
        item.new_quantity = adjustForm!.new_quantity || item.old_quantity;
        item.reason = adjustForm!.adjustment_reason;
        item.isDone = true;
        item.hasErrors = !response.success;
        item.errors = errorsString;
        item.reserved_quantity = adjustForm!.reserved_quantity;
        item.unit = adjustForm!.unit;
      }
    }

    this.operationData = newOperation;
  }

  protected updateBatches() {
    this.batches = this.batches.map(b => {
      const matchingFractions = this.operationData!.items.filter(
        f => f.batch_uid === b.batch_uid
      );
      b.isDone = every(matchingFractions, ["isDone", true]);
      b.hasErrors = some(matchingFractions, ["hasErrors", true]);
      return b;
    });
  }

  protected async fetchAdjustment(adjustmentId: string) {
    this.operationData = await batchOperationsService.getAdjustment(
      adjustmentId
    );
    for (const item of this.operationData!.items) {
      const inventoryBatch = this.batches.find(
        batch => batch.batch_uid === item.batch_uid
      );
      if (inventoryBatch) {
        item.product = inventoryBatch.product!;
        item.tracking_id = inventoryBatch.tracking_id;
        item.unit =
          inventoryBatch!.quantity_unit ||
          inventoryBatch!.unit ||
          inventoryBatch!.total_quantity_unit;
      }
    }
  }

  /** Creates the summary to be displayed in Summary View */
  protected createSummary() {
    this.batches = this.batches.filter(batch => batch.isDone);
    if (this.operationData && this.operationData.items.length) {
      const doneItems = this.operationData.items.filter(item => item.isDone);
      const groupedItems = groupBy(doneItems, "batch_uid");
      this.summaryData = reduce(
        groupedItems,
        (acc: SummaryData[], group) => {
          const first = group[0];
          acc.push({
            product_name: first.product!.name!,
            batch_uid:
              this.bioTrackTraceEnabled &&
              first.biotrack_traceability_id !== null
                ? first.biotrack_traceability_id
                : first.batch_uid,
            secondary_id: first.tracking_id || "--",
            strain:
              (first.product!.strain && first.product!.strain.name) || "--",
            batch_type:
              (first.product!.batch_type && first.product!.batch_type.name) ||
              "--",
            usable_weight_value: first.product!.usable_weight_value
              ? `${first.product!.usable_weight_value} ${first.product!
                  .usable_weight_unit || "u"}`
              : "--",
            rooms: this.getRoomsData(group),
            hasErrors: !!first.hasErrors,
            errors: first.errors || []
          });
          return acc;
        },
        []
      );
    }
  }

  protected getRoomsData(items: AdjustRawItem[]): SummaryRoom[] {
    const rooms = [
      {
        room_name: "room_name",
        current_quantity: "batches.available_quantity",
        reserved_quantity: "batches.reserved_quantity_formatted",
        new_quantity: "batches.new_quantity",
        difference: "difference"
      }
    ];
    let unit: string = "";
    const itemRooms = items.map(item => {
      const itemRoom = this.allRooms.find(
        room => room.id === item.inventory_location_id
      );
      unit = item.unit!;
      return {
        room_name: (itemRoom && itemRoom.name) || "inventory.normal",
        current_quantity: item.old_quantity + item.unit!,
        reserved_quantity: item.reserved_quantity! + item.unit!,
        new_quantity: item.new_quantity + item.unit!,
        difference: {
          value: Math.abs(round(item.new_quantity! - item.old_quantity!, 2)),
          percentage: `(${setPercentage(
            item.new_quantity!,
            item.old_quantity || 1
          )}%)`
        }
      };
    });
    const currentQty = sumArray(items, "old_quantity");
    const newQty = sumArray(items, "new_quantity");
    const reserveQty = sumArray(items, "reserved_quantity");
    const roomsTotal: SummaryRoom = {
      room_name: "total",
      current_quantity: currentQty + unit,
      reserved_quantity: reserveQty + unit,
      new_quantity: newQty + unit,
      difference: {
        value: Math.abs(round(newQty - currentQty, 2)),
        percentage:
          newQty === 0
            ? "(100%)"
            : `(${setPercentage(newQty, currentQty || 1)}%)`
      }
    };
    return [...rooms, ...itemRooms, roomsTotal];
  }

  protected hasMarijuana(): boolean {
    return !!this.batches.find(b => !!b.product_variant.marijuana);
  }

  protected buildForm() {
    const formItems = this.operationData!.items.reduce(
      (acc: AdjustForm[], item) => {
        acc.push({
          batch_uid: item.batch_uid,
          inventory_location_id: item.inventory_location_id,
          old_quantity: item.old_quantity,
          new_quantity: null,
          adjustment_reason: null,
          batch_adjustment_type_id: null,
          batch_fraction_uid: item.batch_fraction_uid,
          unit: item.unit
        });
        return acc;
      },
      []
    );
    for (const item of formItems) {
      const inventoryBatch = this.batches.find(
        bactIndex => bactIndex.batch_uid === item.batch_uid
      );
      const reserve = inventoryBatch!.on_rooms!.find(
        bactIndex =>
          bactIndex.inventory_location.id === item.inventory_location_id &&
          bactIndex.batch_fraction_status_type === "RESERVED"
      );
      item.reserved_quantity = reserve ? reserve.quantity_value : 0;
    }

    this.formDataSummary = this.batches.flatMap(batch => ({
      batch_uid: batch.batch_uid,
      form: this.buildFormForNotAdjust(batch.summary, formItems)
    }));

    const grouped = groupBy(formItems, "batch_uid");
    this.formData = reduce(
      grouped,
      (acc: AdjustItem[], item, batchUID) => {
        acc.push({
          batch_uid: batchUID,
          form: item,
          newQuantityTotal: null,
          difference: 0,
          differencePercentage: 0
        });
        return acc;
      },
      []
    );
  }
  protected buildFormForNotAdjust(
    batchOperation: PBSummary[],
    formItems: AdjustForm[]
  ) {
    batchOperation = batchOperation.filter(
      batchIndex =>
        formItems
          .flatMap(room => room.inventory_location_id)
          .indexOf(batchIndex!.inventory_location.id!) === -1
    );
    const newRooms: AdjustForm[] = [];
    batchOperation.map(inventory => {
      const index = newRooms.findIndex(
        room => room.inventory_location_id === inventory.inventory_location.id
      );
      if (index === -1) {
        newRooms.push({
          inventory_location_id: inventory.inventory_location.id,
          old_quantity:
            inventory.batch_fraction_status_type === "AVAILABLE"
              ? inventory.quantity_value
              : 0,
          reserved_quantity:
            inventory.batch_fraction_status_type === "RESERVED"
              ? inventory.quantity_value
              : 0,
          unit: inventory.quantity_unit
        });
      } else {
        newRooms[index] = {
          inventory_location_id: inventory.inventory_location.id,
          old_quantity: newRooms[index].old_quantity
            ? newRooms[index].old_quantity
            : inventory.batch_fraction_status_type === "AVAILABLE"
            ? inventory.quantity_value
            : 0,
          reserved_quantity: newRooms[index].reserved_quantity
            ? newRooms[index].reserved_quantity
            : inventory.batch_fraction_status_type === "RESERVED"
            ? inventory.quantity_value
            : 0,
          unit: inventory.quantity_unit
        };
      }
    });
    return newRooms;
  }
}
